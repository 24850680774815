<template>
  <window-content v-if="!loading && !errors && !success">
    <div class="m-t">
      <e-row class=" erp-row-col-md">
        <e-col style="max-width: 240px">
          <erp-s-field
                  view="lr"
                  label="ID:"
                  label-width="110px"
          >
            <erp-input simple-border v-model="site.id" disable />
          </erp-s-field>
        </e-col>
      </e-row>

      <e-row class=" erp-row-col-md">
        <e-col>
          <erp-s-field
                  view="lr"
                  label="Nome do site:"
                  label-width="110px"
          >
            <erp-input simple-border v-model="site.nome" />
          </erp-s-field>
        </e-col>

      </e-row>
      <e-row class="erp-row-col-md">
        <e-col>
          <erp-s-field
                  view="lr"
                  label="URL:"
                  label-width="110px"
          >
            <erp-input simple-border v-model="site.url" />
          </erp-s-field>
        </e-col>
      </e-row>
      <e-row class="erp-row-col-md">
        <e-col>
          <erp-s-field
                  view="lr"
                  label="URL webhook:"
                  label-width="110px"
          >
            <erp-input simple-border v-model="site.urlWebhook" />
          </erp-s-field>
        </e-col>
      </e-row>
      <e-row class="erp-row-col-md">
        <e-col>
          <erp-s-field
                  view="lr"
                  label="Token:"
                  label-width="110px"
          >
            <erp-input simple-border v-model="site.token" />
          </erp-s-field>
        </e-col>
      </e-row>
      <e-row class="erp-row-col-md" v-if="site.id">
        <e-col>
          <div class="flex content-between">
            <div class="inline-block text-right self-end col-grow">
              <u-btn label="Renovar cache do site" icon="file" icon-type="fa" icon-style="light" no-caps size="sm"
                     @click="confirmDeleteCache"/>
            </div>
          </div>
        </e-col>
      </e-row>
    </div>
    <window-footer class="flex content-between">
      <div class="inline-block window-actions text-right self-end col-grow">
        <u-btn label="Cancelar" icon="chevron-left" icon-type="fa" icon-style="light" flat no-caps
               @click="$uloc.window.close($root.wid)" />
        <u-btn label="Salvar (F2)" icon="save" icon-type="fa" icon-style="light" flat no-caps
               @click="save"
               v-shortkey="['f2']" @shortkey.native="save" />
      </div>
    </window-footer>
  </window-content>
  <window-success v-else-if="success">
    <div class="text-center">
      <u-icon name="check-circle" color="positive" type="fa" icon-style="solid" class="success-circle-140" />
    </div>
    <h5 v-if="!id" class="m-t">Site registrado com sucesso!</h5>
    <h5 v-else class="m-t">Site atualizado com sucesso!</h5>
    <div class="text-center m-t">
      <timer-close-window ref="timer" @end="$uloc.window.close($root.wid)" />
    </div>
  </window-success>
  <window-loading :errors="errors" :status="status" v-else />
</template>

<script>
  /* eslint-disable */
  import {
    WindowContent,
    WindowBody,
    WindowFooter,
    ErpSField,
    ErpInput,
    ErpLabel,
  } from 'uloc-vue-plugin-erp'
  import WindowLoading from '../../../layout/window/Loading'
  import TimerCloseWindow from '../../../layout/window/TimerClose'
  import WindowSuccess from '../../../layout/window/Success'
  import {
    find,
    newSite,
    updateSite,
    apagarCache
  } from '../../../../domain/sites/services/index'
  import DefaultAsyncSelect from '../../../../reuse/input/DefaultAsyncSelect'
  import ERow from '../../../layout/components/Row'
  import ECol from '../../../layout/components/Col'
  //import {v4 as uuidv4} from 'uuid'

  let mockSite = {
    id: null,
    nome: null,
    url: null,
    urlWebhook: null,
    token: null
  }

  export default {
    name: 'SiteWindow',
    props: ['id'],
    data () {
      return {
        loading: !!this.id,
        errors: null,
        success: false,
        status: null,
        site: JSON.parse(JSON.stringify(mockSite))
      }
    },
    mounted () {
      this.load()
    },
    computed: {},
    watch: {},
    methods: {
      load () {
        this.id && find(this.id || this.site.id)
                .then(response => {
                  this.site = response.data
                  this.$nextTick(() => {
                    this.loading = false
                  })
                })
                .catch((error) => {
                  this.$uloc.dialog({
                    title: 'Falha ao carregar!',
                    color: 'negative',
                    message: `Não conseguimos carregar o site, entre em contato com o administrador.`
                  })
                  this.loading = false
                  this.errors = error.data
                  console.error(error, error.data)
                })
      },
      changeTab ({tab}) {
        this.tabActive = tab
      },
      save () {
        console.log('Save')
        this.loading = true
        let data = JSON.parse(JSON.stringify(this.site))

        this.status = 'Processando dados do site'
        let method = data.id ? updateSite(data.id, data) : newSite(data)
        method
                .then(({data}) => {
                  this.site.id = data.id
                  const cbSuccess = () => {
                    this.success = true
                    this.$nextTick(() => {
                      // this.$refs.timer.start()
                      window.setTimeout(() => {
                        this.loading = false
                        this.success = false
                        this.load()
                      }, 3000)
                      this.$uloc.window.emit(this.$root.wid, 'update', this.props)
                    })
                  }
                  cbSuccess()
                })
                .catch(error => {
                  console.log(error)
                  this.success = false
                  this.errorMessage = error.data.message
                  // this.errors = this.systemError(error)
                  this.$nextTick(() => {
                    this.loading = false
                    this.alertApiError(error)
                    // this.error = true
                  })
                })
      },
      confirmDeleteCache () {
        this.$uloc.dialog({
          title: `Tem certeza que deseja atualizar o cache do site?`,
          message: 'Digite EXCLUIR e clique para confirmar:',
          prompt: {
            model: '',
            type: 'text' // optional
          },
          cancel: true,
          color: 'negative'
        }).then(data => {
          if (data.toUpperCase() !== 'EXCLUIR') {
            this.$uloc.notify({
              color: 'negative',
              message: `Você não confirmou a operação. Exclusão não efetuada.`
            })
            return
          }
          this.$uloc.notify({
            color: 'negative',
            message: `Processando...`
          })
          this.deleteCache()
        }).catch(() => {
        })
      },
      deleteCache () {
        console.log('Excluindo cache...')
        apagarCache().then(response => {
          this.$uloc.dialog({
            title: 'Sucesso!',
            color: 'positive',
            message: `Cache do site renovado com sucesso.`
          })
        })
          .catch(error => {
            this.alertApiError(error)
            this.loading = false
          })
      }
    },
    components: {
      ECol,
      ERow,
      DefaultAsyncSelect,
      WindowSuccess,
      TimerCloseWindow,
      WindowLoading,
      WindowFooter,
      WindowBody,
      WindowContent,
      ErpSField,
      ErpInput,
      ErpLabel,
    }
  }
</script>
